<template>
	<div class="bg-F7F7F7 paddt25">
		<div class="w1200 margin-center">
			<div class="bg-white">
				<div class="paddt25 paddl20 paddr20 font-blod fz20">机构简介</div>
				<div v-html="introduce.desc" class="html-box paddlr10 ">
				</div>
				<div class="paddt25 paddl20 paddr20 font-blod fz16">机构图集</div>
				
				
				<div class=" mart20 paddlr20 display-flex flex-wrap" >
					<!-- <img class="app_06 display-block marr20 marb20"  v-if="i!==0" :src="item" alt="" /> -->
					<el-image class=" app_06 display-block marr20 marb20"  :src="item"  fit="cover"   v-for="(item, i) in  introduce.atlas" :key="i"  @click="preventMove(i,introduce.atlas)">
					</el-image>
				</div>
			</div>
			<div class="mart10 bg-white">
				<div class="paddlr20 fz16 font-blod h40 align-items-center">校区地址</div>
				<div class="paddlr20">
					<div class="paddt16 paddb20">
						<div class="fz16 co-333333 mart5" v-for="(e,i) in introduce.address" :key="i">{{e}}</div>

					</div>
				</div>
			</div>
		</div>
		<!-- 轮播图浴帘 -->
		<div id="mask" v-if="mask">
		    <div class="swiper">
		        <el-carousel trigger="click" height="617px" :interval='2000' :autoplay='false'  arrow="always"  
		            :initial-index='clickindex'>
		            <el-carousel-item v-for="item in templateinfo" :key="item">
		                <el-image style="width: 100%;height: 100%;" :src="item"  fit="cover"  >
		                </el-image>
		            </el-carousel-item>
					<div class="icon-position " @click="stopcick">
					     <i class="el-icon-close" style="font-size: 36px;color: #969696;"></i>
					 </div>
		        </el-carousel>
				
		    </div>
		   
		</div>
	</div>
</template>

<script>
	import {
		useRoute
	} from 'vue-router'
	export default {
		name: 'Introduction',
		data() {
			return {
				detailId: '', //机构id
				introduce: {},
				mask: false,
				clickindex:0,
				templateinfo:[]
			}
		},
		created() {

			let id = useRoute().query.id;
			this.detailId = id;
			this.$req({
				method: 'get',
				url: '/api/agency/detail',
				data: {
					id: this.detailId,
				},
				success: res => {
					console.log(res, "机构详情")
					this.introduce = res;

				},
				fail: error => {}
			});

		},
		methods: {
			stopcick() {
			    document.body.style.overflow = "visible";
			    this.mask = !this.mask;
			},
			preventMove(index,imglist) {
				this.templateinfo = [];
			    document.body.style.overflow = "hidden";
			    this.clickindex = index;
			    this.mask = !this.mask;
				this.templateinfo =imglist
				console.log(this.templateinfo )
			},
		}
	}
</script>

<style  lang="less">
	.icon-position {
	    position: absolute;
	    top: 2%;
	    right: 2%;
	    z-index: 999;
	    display: block;
	}
	#mask {
	    position: fixed;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    z-index: 9999;
	    background-color: rgba(0, 0, 0, .7);
	
	    .swiper {
	        position: absolute;
	        top: 50%;
	        left: 50%;
	        transform: translate(-50%, -50%);
	        width: 1280px;
	        height: 600px;
	        overflow: hidden;
	        border-radius: 10px;
			background-color: #FFFFFF;
	    }
	}
	
	.login_bg {
		width: 1160px;
		height: 388px;
	}

	.app_06 {
		width: 275px;
		height: 212px;
	}

	.app_06:nth-child(4n) {
		margin-right: 0;
	}
</style>
